import axios from "axios";

const actions = {
    async GET_RAZDELS_LIST({ commit }) {
        try {
            const response = await axios.post('api/goods_types', {
                params: {
                    visible: 1,
                    page_num: 1,
                    limit: 100
                },
                action: 'datalist',
                prefix: 'voc',
                headers: {
                    "Access-Control-Allow-Origin": true
                }
            });
            if (response.data.success) {
                let data = response.data.data;
                commit('SET_RAZDELS_LIST', data);
            }
        } catch (e) {
            console.log(e);
        }
    },
    async SYNC_LOCAL_DATA({ commit, state }, sync_data = 'all') {
        try {
            let params = {};
            if (sync_data === 'cart') {
                params = { tg_username: state.userId, cart: state.cart, mode: 'cart' };
            } else if (sync_data === 'wishlist') {
                params = { tg_username: state.userId, wishlist: state.wishlist, mode: 'wishlist' };
            } else {
                params = { tg_username: state.userId, cart: state.cart, wishlist: state.wishlist, mode: 'all' };
            }

            const response = await axios.post('api/clients', {
                params: params,
                action: 'sync_local_data',
                prefix: 'obj',
                headers: {
                    "Access-Control-Allow-Origin": true
                }
            });
            if (response.data.success) {
                let cart = [];
                let wishlist = [];
                if (sync_data === 'cart') {
                    cart = response.data.data.cart ? response.data.data.cart : [];
                    commit('updateStateParam', { title: 'cart', value: cart });
                } else if (sync_data === 'wishlist') {
                    wishlist = response.data.data.wishlist ? response.data.data.wishlist : [];
                    commit('updateStateParam', { title: 'wishlist', value: wishlist });
                } else {
                    cart = response.data.data.cart ? response.data.data.cart : [];
                    wishlist = response.data.data.wishlist ? response.data.data.wishlist : [];
                    commit('updateStateParam', { title: 'cart', value: cart });
                    commit('updateStateParam', { title: 'wishlist', value: wishlist });
                }
            }
        } catch (e) {
            console.log(e);
        }
    },
    async GET_APP_INFO({commit}){
        try {
            const response = await axios.post('api/about', {
                params: {},
                action: 'datainfo',
                prefix: 'obj',
                headers: {
                    "Access-Control-Allow-Origin": true
                }
            });
            if (response.data.success) {
                let data = response.data.data;
                commit('SET_APP_INFO', data);
            }
        } catch (e) {
            console.log(e);
        }
    }
};

export default actions;