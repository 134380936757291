<template>
    <transition name="fade">
        <div class="app-loader" v-if="loading">
            <svg width="147" height="50" viewBox="0 0 147 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1248_3276)">
                    <path d="M147 0H0V50H147V0Z" fill="#243949" />
                    <path
                        d="M111.612 50.0003V38.4796L115.937 36.0201C116.494 35.7031 117.126 35.5353 117.77 35.5336C118.415 35.5319 119.048 35.6963 119.606 36.0103C120.165 36.3243 120.629 36.7768 120.951 37.3222C121.274 37.8675 121.444 38.4865 121.445 39.1167V49.9969H128.448V38.609C128.448 30.9469 119.962 26.1676 113.19 30.0149L111.612 30.8828V16.4141H104.629V50.0003H111.612Z"
                        fill="white" />
                    <path
                        d="M74.2454 44.8894V49.9997H81.2265V45.4196C81.2304 44.4878 81.5048 43.5763 82.0176 42.7911C82.5305 42.006 83.2607 41.3795 84.1233 40.9846L86.2655 39.9896L88.6585 41.2615C89.4556 41.6802 90.1223 42.3018 90.5881 43.0607C91.0539 43.8196 91.3014 44.6875 91.3046 45.5727V49.9986H98.2868V44.841C98.2869 43.0945 97.8337 41.3766 96.9696 39.8484C96.1055 38.3202 94.8589 37.0318 93.3466 36.1039H93.2661L93.2822 36.0938C94.8127 35.1696 96.076 33.8777 96.9519 32.3409C97.8279 30.8041 98.2874 29.0736 98.2868 27.314C98.2868 25.7693 97.9758 24.2397 97.3716 22.8126C96.7674 21.3855 95.8819 20.0888 94.7655 18.9965C93.6491 17.9042 92.3238 17.0378 90.8651 16.4467C89.4065 15.8555 87.8432 15.5513 86.2644 15.5513C84.6856 15.5513 83.1222 15.8555 81.6636 16.4467C80.205 17.0378 78.8796 17.9042 77.7632 18.9965C76.6469 20.0888 75.7613 21.3855 75.1571 22.8126C74.5529 24.2397 74.242 25.7693 74.242 27.314V27.3624C74.2419 29.109 74.6952 30.827 75.5593 32.3553C76.4233 33.8837 77.6699 35.1724 79.1821 36.1006H79.2626L79.2465 36.1096C77.7167 37.0342 76.4541 38.3263 75.5787 39.863C74.7034 41.3998 74.2444 43.1301 74.2454 44.8894ZM86.2655 21.9166C89.0462 21.9166 91.3 24.0812 91.3034 26.7568C91.2978 27.6872 91.0229 28.597 90.5103 29.3808C89.9978 30.1645 89.2688 30.7901 88.4077 31.185L81.613 34.7577L83.968 30.9587L83.8725 30.907C83.0872 30.4936 82.4285 29.883 81.9645 29.1379C81.5004 28.3929 81.2477 27.5403 81.2322 26.6679C81.2851 24.0328 83.5159 21.9166 86.2655 21.9166Z"
                        fill="white" />
                    <path d="M26.4367 29.6104H19.4545V49.9998H26.4367V29.6104Z" fill="white" />
                    <path
                        d="M22.9461 25.1774C23.9188 25.1781 24.8699 24.8965 25.679 24.3682C26.4881 23.84 27.1188 23.0888 27.4915 22.2097C27.8642 21.3306 27.962 20.3631 27.7725 19.4297C27.5831 18.4962 27.115 17.6386 26.4273 16.9655C25.7397 16.2924 24.8634 15.834 23.9094 15.6482C22.9554 15.4624 21.9665 15.5577 21.0679 15.9219C20.1692 16.2861 19.4011 16.9029 18.8608 17.6942C18.3205 18.4856 18.0322 19.416 18.0325 20.3677C18.0325 21.6429 18.5501 22.866 19.4715 23.7679C20.3929 24.6698 21.6427 25.1768 22.9461 25.1774Z"
                        fill="white" />
                    <path d="M39.5105 50.0003L39.514 35.876H52.8375V29.6108H39.514V16.4141H32.5318V50.0003H39.5105Z"
                        fill="white" />
                    <path
                        d="M69.424 50.0002C69.434 49.3623 69.3142 48.7289 69.0716 48.1367C68.829 47.5445 68.4684 47.0055 68.0109 46.5509C67.5534 46.0964 67.008 45.7354 66.4065 45.489C65.8051 45.2426 65.1595 45.1157 64.5075 45.1157C63.8554 45.1157 63.2099 45.2426 62.6084 45.489C62.007 45.7354 61.4616 46.0964 61.0041 46.5509C60.5465 47.0055 60.186 47.5445 59.9434 48.1367C59.7008 48.7289 59.581 49.3623 59.591 50.0002H69.424Z"
                        fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_1248_3276">
                        <rect width="147" height="50" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'loader',
    data() {
        return {
            loading: true,
        }
    },
    mounted() {
        setTimeout(() => {
            this.loading = false;
        }, 1500);
    }
    /*
    computed: {
        isLoading() {
            return this.$store.state.isLoading;
        }
    }
    */
}
</script>
<style scoped>
@import '@/assets/css/loader.css';
</style>