<template>
  <loader />
  <router-view :key="$route.path"></router-view>
  <catalog-filter :is-open="$store.state.isPopupOpen" />
  <my-svg />
  <agency-logo />
</template>

<style>
@import '@/assets/shop-design/css/style.css';
</style>

<script>
import { mapActions } from 'vuex';
import { defineAsyncComponent } from 'vue';

const CatalogFilter = defineAsyncComponent({
  loader: () => import('@/views/components/Modals/CatalogFilter.vue')
});

const AgencyLogo = defineAsyncComponent({
  loader: () => import('@/views/components/AgencyLogo.vue')
});

export default {
  components: {
    CatalogFilter,
    AgencyLogo
  },
  methods: {
    ...mapActions([
      'GET_RAZDELS_LIST',
      'GET_APP_INFO',
      'SYNC_LOCAL_DATA',
    ]),
    telegramInit() {

      //отлавливаем собыие когда телеграм готов к работе
      Telegram.WebApp.ready();

      Telegram.WebApp.MainButton.setParams({
        text: 'ОФОРМИТЬ ЗАКАЗ',
      });

      if (typeof Telegram.WebApp.initDataUnsafe.user !== 'undefined') {
        this.$store.state.$app_mode = 1;
        //расширяем на все окно  
        Telegram.WebApp.expand();
      } else {
        this.$store.state.$app_mode = 2;
      }

    },
    telegramAuthState() {
      if (this.$store.state.$app_mode === 1) {
        this.$store.commit('setUserId', '');
        let dataAuth = {};
        if (typeof Telegram.WebApp.initDataUnsafe.start_param !== 'undefined') {
          dataAuth = { ...Telegram.WebApp.initDataUnsafe.user, ...{ 'start_param': Telegram.WebApp.initDataUnsafe.start_param } };
        } else {
          dataAuth = Telegram.WebApp.initDataUnsafe.user;
        }
        this.$auth(dataAuth);
      } else {
        //авторизация через браузер
      }
    },

  },
  mounted() {
    this.GET_RAZDELS_LIST();
    this.GET_APP_INFO();
    this.telegramInit();
    this.telegramAuthState();
  },
  updated() {
    if (this.$store.state.$app_mode === 1) {
      let curPage = this.$route.name;
      let cart = this.$store.state.cart;
      if (curPage === 'cart' && cart.length > 0) {
        Telegram.WebApp.MainButton.show();
      } else {
        Telegram.WebApp.MainButton.hide();
      }
    }
  }
}
</script>
