//подключаем библиотеки для всего приложения
import Axios from 'axios';
let BASE_URL = '';
if (window.location.hostname === 'localhost') {
    BASE_URL = 'https://telegram.8h.ru/';
} else {
    BASE_URL = window.location.protocol + "//" + window.location.hostname + "/";
}
Axios.defaults.baseURL = BASE_URL;

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import select2 from 'vue3-select2-component';
import LazyLoad from 'vue3-lazyload';

//CORE Vue
import { createApp } from 'vue';
import App from '@/App.vue';
import components from '@/views/components/UI';
import { authTWA } from '@/functions/auth';
import directives from '@/directives';
import store from '@/store';
import router from '@/router';

const app = createApp(App);

//глбальная регистарция axios во всех компонентах через this.$http
app.config.globalProperties.$http = Axios;
app.config.globalProperties.baseURL = BASE_URL;


//регистрируем наши компоненты UI глобально
//чтобы не импортировать каждый раз
components.forEach(component => {
    app.component(component.name, component);
});

//регистрируем дериктивы
directives.forEach(directive => {
    app.directive(directive.name, directive);
});

//регистрируем глобальные функции
//склоняем слова в зависимости от цифры: num_word(12, ['товар', 'товара', 'товаров'])
app.config.globalProperties.$filters = {
    num_word(value, words) {
        value = Math.abs(value) % 100;
        var num = value % 10;
        if (value > 10 && value < 20) return words[2];
        if (num > 1 && num < 5) return words[1];
        if (num == 1) return words[0];
        return words[2];
    },
    price_format(price) {
        return new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            minimumFractionDigits: 2,
            maximumFractionDigits: 20,
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 20
        }).format(price);
    }
}
//auth global function
app.config.globalProperties = {
    ...app.config.globalProperties,
    $auth: authTWA
};

app
    .component('select2', select2)
    .use(LazyLoad, { loading: BASE_URL + 'static/img/loading.gif', error: BASE_URL + 'static/img/loading-error.png', })
    .use(store)
    .use(router)
    .mount('#app');