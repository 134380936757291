const mutations = {
    setUserId: (state, username) => {
        state.userId = username;
    },
    setAuthKey: (state, key) => {
        state.auth_key = key;
    },
    setLoading(state, loading) {
        state.isLoading = loading;
    },
    addToCart(state, productId) {
        let item = state.cart.find(i => i.id === productId)
        if (item) {
            item.quantity++
        } else {
            state.cart.push({ id: productId, quantity: 1 })
        }
    },
    removeFromCart(state, productId) {
        let item = state.cart.find(i => i.id === productId)
        if (item) {
            if (item.quantity > 1) {
                item.quantity--;
            } else {
                state.cart = state.cart.filter(i => i.id !== productId)
            }
        }
    },
    removeFromCartAtAll(state, productId) {
        let item = state.cart.find(i => i.id === productId)
        if (item) {
            state.cart = state.cart.filter(i => i.id !== productId)
        }
    },
    resetCart(state) {
        state.cart = [];
    },
    wishlistToggle(state, productId) {
        let item = state.wishlist.find(i => i.id === productId)
        if (item) {
            state.wishlist = state.wishlist.filter(i => i.id !== productId)
        } else {
            state.wishlist.push({ id: productId })
        }
    },
    setCurrentRazdelId(state, id) {
        state.currentRazdelId = id;
    },
    shareTheUrl(state, params) {
        //if (navigator.canShare) {
        //    navigator.share(params);
        //} else {
        let dummy = document.createElement('input');
        let text = params.url ? params.url : window.location.href;
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        try {
            var successful = document.execCommand('copy');
            document.body.removeChild(dummy);
            var msg = successful ? 'удачно' : 'неудачно';
            alert('Ссылка была ' + msg + ' скопирована');
        } catch (err) {
            alert('Не скопировано');
        }
        //}
    },
    updateStateParam(state, params) {
        let paramTitle = params.title;
        let paramValue = params.value;
        state[paramTitle] = paramValue;
    },
    SET_RAZDELS_LIST(state, data) {
        state.razdelsList = data;
    },
    SET_APP_INFO(state, data) {
        state.appInfo = data;
    },
};

export default mutations;